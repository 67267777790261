import { MenuItem } from "./menu.model";
const SessionDetails = localStorage.getItem("SessionDetails");
export let menuToDisplay: MenuItem[];
menuToDisplay = [
  {
    id: 1,
    label: "Home",
    icon: "bx-home-circle",
    link: "/charts/dashboard",
    dataLink: "/dashboard",
  },
  {
    id: 3,
    label: "Attraction",
    icon: "bx-bar-chart-square",
    link: "/charts/attraction",
    dataLink: "/charts/attraction",
  },
  {
    id: 4,
    label: "Onboarding",
    icon: "bxs-user-detail",
    link: "/charts/onboarding",
    dataLink: "/charts/onboarding",
  },
  {
    id: 2,
    label: "OHBI",
    icon: "bx bxs-dashboard",
    link: "/charts/echart",
    dataLink: "charts/echart",
  },
  {
    id: 5,
    label: "Development",
    icon: "bx bx-user-circle",
    link: "/charts/development",
    dataLink: "/charts/development",
  },
  {
    id: 6,
    label: "Retention",
    icon: "bx bx-receipt",
    link: "/charts/performance",
    dataLink: "/charts/performance",
  },

  {
    id: 7,
    label: "Offboarding",
    icon: "bx bx-dialpad-alt bx-tada-hover",
    link: "/charts/exit",
    dataLink: "/charts/exit",
  },
  {
    id: 8,
    label: "360Survey",
    icon: "bx-rotate-right",
    link: "/charts/survey",
    dataLink: "/charts/survey",
  },
  {
    id: 9,
    label: "Custom Survey",
    icon: "bx bx-receipt",
    link: "/custom-survey/dashboard",
    dataLink: "/custom-survey/dashboard",
  },
  {
    id: 20,
    label: "Organization performance",
    icon: "bx bx-receipt",
    link: "/organizational-goal/list",
    dataLink: "/organizational-goal/list",
  },

  {
    id: 21,
    label: "Employee performance",
    icon: "bx bx-receipt",
    link: "/performance-goal/list",
    dataLink: "/performance-goal/list",
  },
  // {
  //   id: 14,
  //   label: "Annual Survey",
  //   icon: "bx bx-receipt",
  //   link: "/annual-survey/dashboard",
  //   dataLink: "/annual-survey/dashboard",
  // },
  // {
  //   id: 15,
  //   label: "Old",
  //   icon: "bx bx-receipt",
  //   subItems: [
  //     {
  //       id: 16,
  //       label: "Pillars",
  //       link: "/goals/pillers",
  //       dataLink: "/goals/pillers",
  //       parentId: 15,
  //     },
  //     {
  //       id: 19,
  //       label: "Strategic Objectives",
  //       link: "/goals/goal-category",
  //       dataLink: "/goals/goal-category",
  //       parentId: 15,
  //     },
  //     {
  //       id: 17,
  //       label: "Goals",
  //       link: "/goals/goal",
  //       dataLink: "/goals/goal",
  //       parentId: 15,
  //     },
  //     {
  //       id: 18,
  //       label: "Annual Evaluation",
  //       link: "/goals/evaluation",
  //       dataLink: "/goals/evaluation",
  //       parentId: 15,
  //     },
  //   ],
  // },

  {
    id: 10,
    label: "Action Planner",
    icon: "bx bx-dialpad-alt bx-tada-hover",
    link: "/dashboards/planner",
    dataLink: "/dashboards/planner",
  },
  {
    id: 11,
    label: "ICM Store",
    icon: "bx bx-gift",
    link: "/ecommerce/products",
    dataLink: "/ecommerce/products",
  },
  {
    id: 12,
    label: "ICM Community",
    icon: "bx bx-building-house",
    link: "/appointments/list",
    dataLink: "/appointments/list",
  },
  {
    id: 13,
    label: "Settings",
    icon: "bx bx-dialpad-alt bx-tada-hover",
    link: "theme-config",
    dataLink: "/theme-config",
    parentId: 12,
  },
  // {
  //   id: 12,
  //   label: "Reports",
  //   icon: "bx bx-building-house",
  //   link: "/document/reports",
  // },
  // {
  //   id: 12,
  //   label: "Testing",
  //   icon: "bx bx-building-house",
  //   link: "/dashboards/test",
  // },
];
const MENU: MenuItem[] = menuToDisplay;
if (SessionDetails !== null) {
  const myObject = JSON.parse(SessionDetails);
  if (myObject.userRole === 3 && myObject.userRole != null) {
    menuToDisplay = MENU.filter((item) => ![3, 8].includes(item.id));
  } else {
    menuToDisplay = MENU;
  }
} else {
  menuToDisplay = MENU;
}
