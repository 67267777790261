import { EmpRole } from "./emp-role.model";
import { ScoreHistory } from "./general-report/icm-index/score-history.model";
import { OrgAttributeStats } from "./ohbi/org-attribute-stats.model";
import { OrgSurvey } from "./org-survey.model";
import { Region } from "./region.model";
import { Sector } from "./sector.model";
import { SurveyResponse } from "./survey-response.model";
import { DateWiseResponseStats } from "./DateWiseReponse.model";
import { Employeetype } from "./employeetype.model";

export class SurveyDashboard {
  surveyId: number;
  surveyName: string;
  inviteeCount: number;
  organisationId: number;
  orgEmployeeCount: number;

  surveyResponses: SurveyResponse[] = [];

  orgSectors: Sector[] = [];
  orgRegions: Region[] = [];
  orgRoles: EmpRole[] = [];
  orgEmployeeTypeList: Employeetype[] = [];

  regionStats: OrgAttributeStats[] = [];
  sectorStats: OrgAttributeStats[] = [];
  empRoleStats: OrgAttributeStats[] = [];
  genderStats: OrgAttributeStats[] = [];
  expStats: OrgAttributeStats[] = [];

  globalStats: ScoreHistory[] = [];
  questionWiseStats: ScoreHistory[] = [];

  orgSurveyStats: OrgSurvey[] = [];
  DateWiseResponseStats: DateWiseResponseStats[]=[];
  static getSurveyDashboard(surveydata): SurveyDashboard {
    var surveyDashboard = new SurveyDashboard();
    surveyDashboard.surveyId = surveydata.surveyId;
    surveyDashboard.surveyName = surveydata.surveyName;
    surveyDashboard.inviteeCount = surveydata.inviteeCount;
    surveyDashboard.organisationId = surveydata.organisationId;
    surveyDashboard.orgEmployeeCount = surveydata.orgEmployeeCount;

    surveyDashboard.orgSectors = Sector.getSectors(surveydata.orgFilters.orgSectors);
    surveyDashboard.orgRegions = Region.getRegions(surveydata.orgFilters.orgRegions);
    surveyDashboard.orgRoles = EmpRole.getEmpRoles(surveydata.orgFilters.orgEmpRoles);
    surveyDashboard.orgEmployeeTypeList = Employeetype.getEmployeeType(surveydata.orgFilters.orgEmployeeTypeList);

    surveyDashboard.regionStats = OrgAttributeStats.getRegionStats(surveydata.orgOverseasStats.regionWiseStats);
    surveyDashboard.sectorStats = OrgAttributeStats.getSectorStats(surveydata.orgOverseasStats.sectorWiseStats);
    surveyDashboard.empRoleStats = OrgAttributeStats.getEmpRoleStats(surveydata.orgOverseasStats.empRoleWiseStats);
    surveyDashboard.genderStats = OrgAttributeStats.getGenderStats(surveydata.orgOverseasStats.genderWiseStats);
    surveyDashboard.expStats = OrgAttributeStats.getExperienceStats(surveydata.orgOverseasStats.experienceWiseStats);

    surveyDashboard.surveyResponses = [];
    surveyDashboard.surveyResponses = SurveyResponse.getSurveyResponses(surveydata.surveyData);

    surveyDashboard.orgSurveyStats = OrgSurvey.getOrgSurveyTrends(surveydata.orgSurveyList);
    surveyDashboard.DateWiseResponseStats = DateWiseResponseStats.getOrgSurveyTrends(surveydata.dateWiseResponseStats);
    if (surveydata.scoreHistory != null) {
      surveyDashboard.globalStats = ScoreHistory.getGlobalStats(surveydata.scoreHistory.globalScore);
      surveyDashboard.questionWiseStats = ScoreHistory.getQuestionWiseStats(surveydata.scoreHistory.questionWiseScore);
    }
    return surveyDashboard;
  }
}
