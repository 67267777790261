import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { OverseasStats } from "src/app/model/overseas-stats.model";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  public dashboardSubject: BehaviorSubject<any>;
  public dashboardData: Observable<any>;

  public authenticationSubject: BehaviorSubject<any>;
  public authenticationData: Observable<any>;

  public surveyListSubject: BehaviorSubject<any>;
  public orgSurveyList: Observable<any>;

  public currentSurveySubject: BehaviorSubject<any>;
  public currentSurvey: Observable<any>;

  public orgOverallStatsSubject: BehaviorSubject<OverseasStats>;
  public orgOverallStatsData: Observable<OverseasStats>;

  constructor() {
    // this.dashboardSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userSurvey')));

    // this.dashboardSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("DashboardSubject")));

    this.dashboardSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("DashboardData")) ||  null);

    this.dashboardData = this.dashboardSubject.asObservable();

    this.authenticationSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("SessionDetails")));
    this.authenticationData = this.authenticationSubject.asObservable();

    this.surveyListSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("surveyListSubject")));
    this.orgSurveyList = this.surveyListSubject.asObservable();

    this.currentSurveySubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("currentSurveySubject")));
    this.currentSurvey = this.currentSurveySubject.asObservable();

    this.orgOverallStatsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("orgOverallStatsSubject")));
    
    this.orgOverallStatsData = this.orgOverallStatsSubject.asObservable();
   
  }
 
}
